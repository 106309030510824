html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}
body {
  height: 100%;
  width: 100%;
}
#root {
  height: 100%;
  width: 100%;
}

[data-aos="scrollArrow"].aos-animate {
  width: 100%;
}